import React from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  ContentfulComponentSiteFooter,
  ContentfulComponentSiteHeader,
  NotFoundPageQuery,
} from '../../graphql-types';
import edgepointTheme from '../themes/edgepoint';
import cymbriaTheme from '../themes/cymbria';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SEO from '../components/SEO';
import { useGlobalState } from '../hooks/useGlobalState';
import { WEBSITE } from '../types/website.enum';
import Layout from '../components/Layout';
import Typography from '../components/Typography';
import Divider from '../components/Divider';
import { useLocalization } from 'gatsby-theme-i18n';
import { getTranslation } from '../services/translation.service';
import CookiePreferences from '../components/CookiePreferences';

export const query = graphql`
  query NotFoundPage(
    $globalWebsiteContentfulId: String!
    $node_locale: String!
  ) {
    contentfulGlobalWebsite(
      contentful_id: { eq: $globalWebsiteContentfulId }
      node_locale: { eq: $node_locale }
    ) {
      ...WebsiteFragment
      homePage {
        seo {
          ...ContentfulComposeSeoFragment
        }
      }
    }
  }
`;
const NotFoundPage: React.FC<PageProps<NotFoundPageQuery>> = (props) => {
  const { data, location } = props;
  const { contentfulGlobalWebsite } = data;
  const { locale } = useLocalization();

  // setup global state now that we have access to page data
  const { site } = useGlobalState();
  const mobileMenuPaddingClasses = 'pt-m4 lg:pt-0';

  const pageNotFoundString = getTranslation('PageNotFound', locale);
  const pageDoesNotExistString = getTranslation('PageDoesNotExist', locale);

  const cookiePreferences = contentfulGlobalWebsite?.cookiePreferences;

  return (
    <Layout theme={site === WEBSITE.EDGEPOINT ? edgepointTheme : cymbriaTheme}>
      <SEO data={contentfulGlobalWebsite?.homePage?.seo} location={location} />
      <Header
        data={
          contentfulGlobalWebsite?.header as unknown as ContentfulComponentSiteHeader
        }
        location={location}
        logo={contentfulGlobalWebsite?.siteAssets?.logo}
        mobileLogo={contentfulGlobalWebsite?.siteAssets?.mobileLogo}
      />
      <main id="main" className={`container ${mobileMenuPaddingClasses}`}>
        <div className="pt-m4 pb-l4">
          <Typography as="h1" variant="h1" className="uppercase">
            Error 404
          </Typography>
          <Divider
            fullWidth
            color={
              site === WEBSITE.EDGEPOINT
                ? edgepointTheme.color.secondary
                : cymbriaTheme.color.secondary
            }
            className="mt-s2"
          />
          <Typography as="h3" variant="h3" className="mt-s2">
            {pageNotFoundString}
          </Typography>
          <Typography as="p" variant="body" className="">
            {pageDoesNotExistString}
          </Typography>
        </div>
      </main>
      <Footer
        data={
          contentfulGlobalWebsite?.footer as unknown as ContentfulComponentSiteFooter
        }
        logo={contentfulGlobalWebsite?.siteAssets?.logo}
        socialLinks={contentfulGlobalWebsite?.siteAssets?.socialLinks}
      />
      {cookiePreferences && (
        <CookiePreferences
          id={cookiePreferences?.id}
          title={cookiePreferences?.title}
          description={cookiePreferences?.description}
          manageConsentPreferencesTitle={
            cookiePreferences?.manageConsentPreferencesTitle
          }
          cookieDuration={cookiePreferences?.cookieDuration}
          cookies={cookiePreferences?.cookies}
          acceptAllCookiesButton={cookiePreferences?.acceptAllCookiesButton}
          rejectAllCookiesButton={cookiePreferences?.rejectAllCookiesButton}
          manageCookiePreferencesButton={
            cookiePreferences?.manageCookiePreferencesButton
          }
          acceptChoosenPreferencesButton={
            cookiePreferences?.acceptChoosenPreferencesButton
          }
          location={location}
        />
      )}
    </Layout>
  );
};

export default NotFoundPage;
